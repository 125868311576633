<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"></div>
		<div class="content page-show-procedure opacity-page">
			<div class="content-procedure">
				<div class="title-bar" v-if="showTraining == true">
					<div class="title">
						<div class="title-name" v-show="$resize && $mq.above(781)">
							{{ $t("procedures.steps") }}
						</div>
					</div>
					<div class="title">
						<div class="title-name" v-show="$resize && $mq.above(781)">
							{{ $t("procedures.trainings") }}
						</div>
						<div class="button">
							<button class="icon-show-details" @click="showTraining = false">
								<icon-close />
							</button>
						</div>
					</div>
				</div>
				<template v-if="showTraining">
					<div class="step" v-for="(step, index) in stepsRender" :class="showTraining == true ? 'trainings' : ''">
						<div v-show="$resize && $mq.above(781)" class="step-count">
							<div class="number">{{ index + 1 }}</div>
						</div>
						<div v-show="$resize && $mq.below(780)" class="title-step">
							{{ $t("procedures.step_count", { n: index + 1 }) }}:
						</div>
						<div class="data body-trainings">
							<div class="body body-trainings" v-html="step.body"></div>
						</div>
						<div class="data trainings">
							<div class="body trainings" v-html="step.training"></div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="step" v-for="(step, index) in stepsRender">
						<div v-show="$resize && $mq.above(781)" class="step-count">
							<div class="number">{{ index + 1 }}</div>
						</div>
						<div v-show="$resize && $mq.below(780)" class="title-step">
							{{ $t("procedures.step_count", { n: index + 1 }) }}:
						</div>
						<div class="data no-explication">
							<div class="header-accordion" data-toggle="collapse" :data-target="`#collapseStep${step.id}`">
								<div class="body" v-html="step.body"></div>
								<div class="actions">
									<button
										class="arrow-collapse show collapsed"
										type="button"
										data-toggle="collapse"
										:data-target="`#collapseStep${step.id}`"
										aria-expanded="true"
									>
										<icon-arrow />
									</button>
								</div>
							</div>
							<div class="collapse content-accordion" :id="`collapseStep${step.id}`">
								<div class="training">
									<div class="body" v-html="step.training" v-if="step.training"></div>
									<div class="body" v-else>{{ $t("procedures.step_no_training") }}</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>

			<div v-if="$resize && $mq.above(1025) && showTraining == false" class="space-right-body-step"></div>

			<div class="sidebar-procedure" v-if="showTraining == false">
				<div class="header small-margin">
					<!-- <div class="title">{{ procedure.name }}</div> -->
					<div class="title">{{ $t("procedures.view_procedure_version") }}</div>

					<div class="dropdown dropdown-status-procedure" v-if="procedure.rights.entity_crud">
						<div
							class="status-procedure"
							v-bind:class="{ active: !viewDraft && procedure.status == 'live' }"
							:ref="'dropdownChangeStatusRef' + procedure.id"
							:id="'dropdownChangeStatus' + procedure.id"
							data-toggle="dropdown"
						>
							{{ viewDraft ? $t("procedures.draft") : $t("procedures.live") }} <icon-arrow />
						</div>

						<div
							class="dropdown-menu dropdown-menu-right"
							:id="'dropdownMenu' + procedure.id"
							:aria-labelledby="'dropdownChangeStatus' + procedure.id"
						>
							<div
								class="dropdown-item"
								:class="{ active: !viewDraft }"
								@click="changeViewVersion('live')"
								v-if="procedure.status == 'live'"
							>
								<div class="simple-text">{{ $t("procedures.live") }}</div>
							</div>
							<div class="dropdown-item" :class="{ active: viewDraft }" @click="changeViewVersion('draft')">
								<div class="simple-text">{{ $t("procedures.draft") }}</div>
							</div>
						</div>
					</div>

					<div class="status-procedure" v-else v-bind:class="{ active: !viewDraft && procedure.status == 'live' }">
						{{ !viewDraft ? $t("procedures.live") : $t("procedures.draft") }}
					</div>
				</div>
				<div class="actions-header">
					<button
						v-if="procedure.rights.entity_crud && !(viewDraft && procedure.draft_steps.length == 0)"
						@click="showModal('publish', { procedure: procedure, type: 'procedure', from: 'procedures', viewDraft: viewDraft })"
						class="publish-btn"
						:class="{ active: viewDraft || procedure.status == 'draft' }"
					>
						<div class="text">
							{{ !viewDraft && procedure.status == "live" ? $t("procedures.make_draft") : $t("procedures.make_live") }}
						</div>
					</button>
					<button
						v-if="procedure.rights.entity_crud && viewDraft"
						class="edit-procedure-btn"
						@click="$router.push({ name: 'procedure-edit', params: { slug: procedure.slug } })"
					>
						<div class="text">{{ $t("procedures.edit_procedure") }}</div>
					</button>
				</div>

				<div class="manager-row" v-if="procedure.responsible">
					<div class="manager">
						<div class="image">
							<img :src="procedure.responsible.avatar" v-if="procedure.responsible.avatar" />
							<div class="user-circle" v-else>
								<icon-user />
							</div>
						</div>
						<div class="data">
							<div class="tag">{{ $t("procedures.responsible") }}</div>
							<div class="name">
								{{
									`${procedure.responsible.first_name} ${
										procedure.responsible.last_name ? procedure.responsible.last_name : ""
									}`
								}}
							</div>
						</div>
					</div>
					<v-popover
						class="tracking-procedure w-margin"
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 0, hide: 0 }"
					>
						<button class="icon-tracking" @click="showTraining = true">
							<icon-teacher />
						</button>
						<template slot="popover">
							<div class="simple-text">{{ $t("procedures.detailed") }}</div>
						</template>
					</v-popover>
					<v-popover
						class="tracking-procedure"
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 0, hide: 0 }"
					>
						<button
							class="icon-tracking"
							@click="
								procedure.tracking
									? showModal('procedure_check', { id: procedure.tracking, userSlug: $auth.user().slug })
									: trackProcedure()
							"
							:class="[procedure.tracking ? 'progress' : 'start']"
							v-if="procedure.status == 'live'"
						>
							<icon-start-tracking />
						</button>

						<button class="icon-tracking disallowed" v-else>
							<icon-start-tracking />
						</button>

						<template slot="popover">
							<div class="simple-text" v-if="procedure.status == 'live'">
								{{
									procedure.tracking
										? $t("procedures_check.tracking_procedure")
										: $t("procedures_check.not_tracking_procedure")
								}}
							</div>
							<div class="simple-text" v-else>{{ $t("procedures.cant_be_tracked") }}</div>
						</template>
					</v-popover>
				</div>

				<div class="box-label-text" v-if="procedure.roles.length">
					<div class="label">{{ $t("procedures.roles") }}</div>
					<div class="text">
						{{
							procedure.roles
								.map((el) => {
									return el["name"];
								})
								.join(", ")
						}}
					</div>
				</div>

				<div class="box-label-text" v-if="procedure.categories && procedure.categories.length">
					<div class="label">{{ $t("procedures.categories") }}</div>
					<div class="text">
						{{
							procedure.categories
								.map((el) => {
									return el["name"];
								})
								.join(", ")
						}}
					</div>
				</div>

				<div class="box-label-text">
					<div class="label">{{ $t("procedures.reading_time") }}</div>
					<div class="text">{{ time_reading }}</div>
				</div>

				<div class="last-update" v-if="procedure.total_procedure_histories">
					<router-link :to="{ name: 'procedure-history', params: { slug: procedure.slug } }" class="btn-tbf-link"
						>{{ $tc("procedures.count_updates", procedure.total_procedure_histories) }}, {{ $t("general.last_update") }}
						{{ procedure.updated_at | moment("DD MMM YYYY") }}</router-link
					>
				</div>

				<div class="accordion-sidebar" v-if="procedure.triggers.length">
					<div class="header-accordion" data-toggle="collapse" data-target="#collapseTrigger">
						<div class="icon"><icon-trigger /></div>

						<div class="title">{{ $t("procedures.triggers") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show"
								type="button"
								data-toggle="collapse"
								data-target="#collapseTrigger"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion show" id="collapseTrigger">
						<div class="box-radius" v-for="trigger in procedure.triggers">
							<div class="data">
								<div class="label">
									{{
										trigger.type == "weekly" || trigger.type == "yearly"
											? $tc("trigger.text." + trigger.type, trigger.value.split(",").length)
											: $t("trigger.text." + trigger.type)
									}}
								</div>
								<div class="value" v-if="trigger.type == 'procedure'">{{ trigger.entity_name }}</div>
								<template v-if="trigger.type == 'date'">
									<template v-if="trigger.custom_value && trigger.custom_value.length">
										<div class="value weeks">
											{{
												trigger.custom_value
													.map((a) => {
														return $t(`trigger.value.measurement_dynamically.${a}`);
													})
													.join(", ")
													.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
											}}
										</div>
										<div class="value weeks">
											{{
												$t(`trigger.value.date_weekly`, {
													value: trigger.value
														.split(",")
														.map((el) => {
															return $t(`calendar.long_days.${el}`);
														})
														.join(", "),
												}).replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
											}}
										</div>
									</template>
									<div class="value" v-else>{{ $t(`trigger.value.date_monthly`, { value: trigger.value }) }}</div>
								</template>
								<div class="value" v-if="trigger.type == 'weekly'">
									{{
										$t(
											trigger.value
												.split(",")
												.map((el) => {
													return $t(`calendar.long_days.${el}`);
												})
												.join(", ")
										)
									}}
								</div>
								<div class="value" v-if="trigger.type == 'event'">{{ trigger.value }}</div>
								<div class="value" v-if="trigger.type == 'yearly'">{{ trigger.value }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseStatistics">
						<div class="icon"><icon-statistics /></div>
						<div class="title">{{ $t("procedures.statistics") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseStatistics"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseStatistics">
						<div class="box-radius">
							<div class="inline-data">
								<div class="label">{{ $t("procedures.total_time") }}</div>
								<div class="value">{{ procedure.analytics.total_time }}</div>
							</div>
							<div class="inline-data show-modal">
								<div class="label">{{ $t("procedures.number_visits") }}</div>
								<div class="value" @click="showModal('entries_procedure_users', procedure.slug)">
									{{ procedure.analytics.entries }}
								</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("procedures.average_time") }}</div>
								<div class="value">{{ procedure.analytics.average_time }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseImprovements">
						<div class="icon"><icon-question /></div>
						<div class="title">{{ $t("procedures.improvement_requests") }} ({{ procedure.total_improvement_proposals }})</div>
						<div class="actions">
							<button
								class="btn-tbf blue center add-btn"
								@click.stop="showModal('improvements', { id: procedure.id, type: 'procedures' })"
							>
								<div class="text">{{ $t("procedures.add_improvement") }}</div>
							</button>
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseImprovements"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div v-if="procedure.improvement_proposals.length" class="collapse content-accordion" id="collapseImprovements">
						<div class="box-radius box-improvement" v-for="improvement in procedure.improvement_proposals">
							<div class="header-user">
								<div class="user">
									<div class="image">
										<img :src="improvement.user.avatar" v-if="improvement.user.avatar" />
										<div class="user-circle" v-else>
											<icon-user />
										</div>
									</div>
									<div class="data">
										<div class="tag">{{ improvement.created_at | moment("DD MMM YYYY") }}</div>
										<div class="name">
											{{
												`${improvement.user.first_name} ${
													improvement.user.last_name ? improvement.user.last_name : ""
												}`
											}}
										</div>
									</div>
								</div>
								<div class="dropdown dropdown-status">
									<div
										class="status"
										:ref="'dropdownEditRef' + improvement.id"
										:id="'dropdownEdit' + improvement.id"
										data-toggle="dropdown"
										v-if="procedure.rights.entity_crud"
									>
										<div
											class="circle-status"
											v-bind:class="{
												green: improvement.status == 'active',
												blue: improvement.status == 'featuring',
												orange: improvement.status == 'archived',
											}"
										></div>
										<div class="text">{{ $t("improvements.status." + improvement.status) }}</div>
									</div>

									<div class="status" v-else>
										<div
											class="circle-status"
											v-bind:class="{
												green: improvement.status == 'active',
												blue: improvement.status == 'featuring',
												orange: improvement.status == 'archived',
											}"
										></div>
										<div class="text">{{ $t("improvements.status." + improvement.status) }}</div>
									</div>

									<div
										class="dropdown-menu dropdown-menu-right"
										:id="'dropdownMenu' + improvement.id"
										:aria-labelledby="'dropdownEdit' + improvement.id"
										v-if="procedure.rights.entity_crud"
									>
										<div
											class="dropdown-item"
											v-if="improvement.status != 'active'"
											@click="
												showModal('change_improvement_status', {
													id: improvement.id,
													status: 'active',
													from: 'show',
												})
											"
										>
											<div class="simple-text">{{ $t("improvements.action.activate") }}</div>
										</div>
										<div
											class="dropdown-item"
											v-if="improvement.status != 'archived'"
											@click="
												showModal('change_improvement_status', {
													id: improvement.id,
													status: 'archived',
													from: 'show',
												})
											"
										>
											<div class="simple-text">{{ $t("improvements.action.archived") }}</div>
										</div>
										<div
											class="dropdown-item"
											v-if="improvement.status != 'featuring'"
											@click="
												showModal('change_improvement_status', {
													id: improvement.id,
													status: 'featuring',
													from: 'show',
												})
											"
										>
											<div class="simple-text">{{ $t("improvements.action.featuring") }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text">{{ improvement.body }}</div>
							<div class="files">
								<div class="file" v-for="file in improvement.files">
									<v-popover
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 0, hide: 0 }"
									>
										<a class="file-box" :href="file.file_path" target="_blank">
											<div class="icon"><icon-file /></div>
										</a>

										<template slot="popover">
											<div class="simple-text">{{ file.filename }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
						<div class="view-more" v-if="procedure.improvement_proposals.length">
							<router-link
								:to="{ name: 'procedure-improvements', params: { slug: procedure.slug } }"
								class="btn-tbf grey center view-more-btn"
							>
								<div class="text">{{ $t("improvements.view_requests") }}</div>
							</router-link>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseExport">
						<div class="icon"><icon-download /></div>
						<div class="title">{{ $t("procedures.export") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseExport"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseExport">
						<div class="box-radius border-0">
							<div class="inline-data">
								<div class="label">{{ $t("procedures.pdf") }}</div>
								<div class="value link" @click="downloadPdf">
									<div class="loader-spin" v-if="loadingExportPdf"></div>
									{{ $t("procedures.download_pdf") }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
	<procedure-show-loader v-else />
</template>

<script>
import IconArrow from "../../Icons/Arrow";
import IconUser from "../../Icons/User";
import IconEdit from "../../Icons/Edit";
import IconFile from "../../Icons/File";
import IconFilePdf from "../../Icons/FilePdf";
import IconDownload from "../../Icons/Download";
import IconQuestion from "../../Icons/Question";
import IconTrigger from "../../Icons/Trigger";
import IconStatistics from "../../Icons/Statistics";
import ProcedureShowLoader from "../../PagesLoaders/ProceduresShow.vue";
import IconStartTracking from "../../Icons/StartTracking";
import IconTeacher from "../../Icons/Teacher";
import IconClose from "../../Icons/Close";

export default {
	components: {
		IconArrow,
		IconUser,
		IconQuestion,
		IconTrigger,
		IconEdit,
		IconFile,
		IconStatistics,
		IconFilePdf,
		IconDownload,
		IconStartTracking,
		ProcedureShowLoader,
		IconTeacher,
		IconClose,
	},
	data() {
		return {
			loaded: false,
			procedure: {},
			timer: {
				startTime: "",
				timeOnPage: 0,
				timePaused: "start",
				countTime: "",
			},
			time_reading: "",
			viewDraft: false,
			stepsRender: [],
			loadingExportPdf: false,
			showTraining: false,
		};
	},
	created() {
		window.addEventListener("beforeunload", this.storeTime);
	},
	async mounted() {
		await this.getProcedure();

		this.$root.$on("refreshProcedureShow", (data = false) => {
			if (data) {
				this.viewDraft = false;
				let query = Object.assign({}, this.$route.query);
				delete query.draft_version;
				this.$router.replace({ query }).catch(() => {});
			}

			this.getProcedure();
		});

		this.$root.$on("refreshProcedureTracked", () => {
			this.procedure.tracking = null;
		});

		this.countTimeOnPage();

		$(window).on("blur focus", (e) => {
			var prevType = $(this).data("prevType");
			if (prevType != e.type) {
				switch (e.type) {
					case "blur":
						this.timePaused = "stop";
						break;
					case "focus":
						this.timePaused = "start";
						break;
				}
			}

			$(this).data("prevType", e.type);
		});
	},
	beforeDestroy() {
		clearInterval(this.timer.countTime);

		axios.post(`/procedure-logs/${this.procedure.slug}/store`, { time: this.timer.timeOnPage }).catch((error) => {
			if (error.response) {
				if (error.response.status == 500) {
					alert(this.$t("error.500"));
				}
			}
		});

		window.removeEventListener("beforeunload", this.storeTime);
	},
	methods: {
		async getProcedure() {
			await axios
				.get(`procedures/${this.$route.params.slug}`)
				.then(({ data }) => {
					this.procedure = data.data;
					const text = data.data.steps.map((el) => [el.body]);
					const words = text
						.join(" ")
						.replace(/(&nbsp;|&gt;|&lt;|&amp|<br>|<br \/>|<[^>]*>?)/gm, " ")
						.trim()
						.split(/\s+/);
					const wpm = 225;
					const time = words.length / wpm;
					if (moment.duration(time, "minutes")._data.minutes > 0 && moment.duration(time, "minutes")._data.seconds > 0) {
						this.time_reading =
							moment.duration(time, "minutes")._data.minutes +
							" " +
							this.$t("general.minutes") +
							" " +
							this.$t("general.and") +
							" " +
							this.$tc("general.count_seconds", moment.duration(time, "minutes")._data.seconds);
					} else if (moment.duration(time, "minutes")._data.minutes == 0 && moment.duration(time, "minutes")._data.seconds > 0) {
						this.time_reading = this.$tc("general.count_seconds", moment.duration(time, "minutes")._data.seconds);
					} else if (moment.duration(time, "minutes")._data.minutes > 0 && moment.duration(time, "minutes")._data.seconds == 0) {
						this.time_reading = this.$tc("general.count_minutes", moment.duration(time, "minutes")._data.minutes);
					} else if (moment.duration(time, "minutes")._data.minutes == 0 && moment.duration(time, "minutes")._data.seconds == 0) {
						this.time_reading = this.$t("general.a_second");
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						var title = this.procedure.name;
						var backRoute = "procedures";
						this.$root.$emit("navbar_title", title, backRoute);

						if (this.$route.query.draft_version || this.procedure.status == "draft") {
							this.viewDraft = true;
						}
						this.stepsRender = this.viewDraft ? this.procedure.draft_steps : this.procedure.steps;

						setTimeout(() => {
							this.loaded = true;
							setTimeout(() => {
								$(".opacity-page").addClass("show");
							}, 0);
						}, 0);
					}, 0);
				});
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		countTimeOnPage() {
			this.timer.startTime = moment().format("DD-MM-YYYY HH:mm:ss");
			this.timer.countTime = setInterval(() => {
				if (this.timer.timePaused == "start") {
					this.timer.timeOnPage += 1;
				}
			}, 1000);
		},
		storeTime(event) {
			event.preventDefault();
			try {
				axios.post(`/procedure-logs/${this.procedure.slug}/store`, { time: this.timer.timeOnPage });
				return "";
			} catch (error) {
				if (error.response) {
					if (error.response.status == 500) {
						alert(this.$t("error.500"));
					}
				}
			}
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		changeViewVersion(status) {
			if (status == "live") {
				let query = Object.assign({}, this.$route.query);
				delete query.draft_version;
				this.$router.replace({ query });
				this.stepsRender = this.procedure.steps;
				this.viewDraft = false;
			} else {
				this.stepsRender = this.procedure.draft_steps;
				this.viewDraft = true;
				this.$router.replace({ query: { draft_version: true } });
			}
		},
		changeStatusProcedure() {
			this.loaded = false;

			if (this.viewDraft) {
				axios
					.post(`/procedures/${this.procedure.slug}/change-status`, { status: "live", source_steps: "draft" })
					.then(({ data }) => {
						this.procedure.steps = data.data.steps;
						this.procedure.draft_steps = data.data.draft_steps;
						this.procedure.status = data.data.status;
						this.stepsRender = this.procedure.steps;
						this.viewDraft = false;

						let query = Object.assign({}, this.$route.query);
						delete query.draft_version;
						this.$router.replace({ query });
					})
					.finally(() => {
						setTimeout(() => {
							this.loaded = true;
							setTimeout(() => {
								$(".opacity-page").addClass("show");
							}, 0);
						}, 0);
					});
			} else {
				if (this.procedure.status == "draft") {
					axios
						.post(`/procedures/${this.procedure.slug}/change-status`, { status: "live", source_steps: "live" })
						.then(({ data }) => {
							this.procedure.steps = data.data.steps;
							this.procedure.draft_steps = data.data.draft_steps;
							this.procedure.status = data.data.status;
							this.stepsRender = this.procedure.steps;
						})
						.finally(() => {
							setTimeout(() => {
								this.loaded = true;
								setTimeout(() => {
									$(".opacity-page").addClass("show");
								}, 0);
							}, 0);
						});
				} else {
					axios
						.post(`/procedures/${this.procedure.slug}/change-status`, { status: "draft" })
						.then(({ data }) => {
							this.procedure.steps = data.data.steps;
							this.procedure.draft_steps = data.data.draft_steps;
							this.procedure.status = data.data.status;
							this.stepsRender = this.procedure.steps;
						})
						.finally(() => {
							setTimeout(() => {
								this.loaded = true;
								setTimeout(() => {
									$(".opacity-page").addClass("show");
								}, 0);
							}, 0);
						});
				}
			}
		},
		downloadPdf() {
			this.loadingExportPdf = true;

			axios
				.get(`reports/${this.procedure.slug}/procedure-pdf`, {
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.procedure.name + ".pdf";

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();

					setTimeout(() => {
						this.loadingExportPdf = false;
					}, 100);
				});
		},
		trackProcedure() {
			axios
				.post("/procedure-checks/store", {
					procedure_id: this.procedure.id,
					status: "open",
					checked_steps: [],
					total_steps: this.procedure.steps.length,
					completed: 0,
				})
				.then(({ data }) => {
					this.showModal("procedure_check", { id: data.data.id, userSlug: this.$auth.user().slug });
					this.procedure.tracking = data.data.id;
				});
		},
		expandTrainer() {},
	},
};
</script>
